import './FunctionDebug.css';
import React, { useState, useEffect } from "react";
import { Textarea, Button, Card, HelpText } from 'react-rainbow-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';


const containerStyles = {
  maxWidth: 700,
};

function FunctionDebug({setErrorState}) {

  const [query, setQuery] = useState('');
  const [code, setCode] = useState('');
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [buttonClick, setButtonClick] = useState(null);
  const [count, setCount] = useState(0);
  const [codeCount, setCodeCount] = useState(0);

  const maxLength = 500
  const placeholder = `Enter Problem Summary.

  ex. This function returns orange but I want it to return the length of the array`

  const codePlaceholder = `Enter function code.

  ex. var array = ["blueberry","strawberry","pineapple","banana","apple","orange"];
  return array[array.length]`;

  useEffect(() => {

    async function getResponse() {
      try {
        setLoading(true);
        var response = await fetch(`https://eqesbupceg.execute-api.us-east-1.amazonaws.com/debug?query=${query}&code=${code}`);
        if (!response.ok) {
          throw new Error(`HTTP Error: ${response.status}`);
        }
        response = await response.json();
        console.log(response.response);
        setResponse(response.response.trim());
      } catch (e) {
        console.log(e.message);
        setErrorState(e.message);
      } finally {
        setLoading(false);
      }
    }
    if (query) {
      getResponse();
    }
  }, [buttonClick]);

  async function handleClick() {
    const date = new Date();
    setButtonClick(date.toISOString());
  }


  return (
    <>
    <div>
      <h1>Function Debug</h1>
      <Textarea
        header={<HelpText style={{margin: "5px"}} text="Feel free to include context, such as what software is causing the problem. I.e. Prefix 'NodeJS' to the error to help isolate the cause."/>}
        id="function-debug-query"
        maxLength={maxLength}
        value={query}
        rows={6}
        placeholder={placeholder}
        footer ={<div className='function-debug-footer'>{count}/{maxLength}</div>}
        style={containerStyles}
        className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
        onChange={e => {setQuery(e.target.value); setCount(e.target.value.length)}}
      />
      <br />
      <Textarea
        header={<HelpText style={{margin: "5px"}} text="Setting test variables will be helpful"/>}
        id="function-debug-code"
        maxLength={maxLength}
        value={code}
        rows={8}
        placeholder={codePlaceholder}
        footer ={<div className='function-debug-footer'>{codeCount}/{maxLength}</div>}
        style={containerStyles}
        className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
        onChange={e => {setCode(e.target.value); setCodeCount(e.target.value.length)}}
      />
      <br />
      <Button variant="brand" className="rainbow-m-around_medium" onClick={handleClick} isLoading={loading}>
        Rubber duck it... 
        <FontAwesomeIcon icon={faArrowRight} className="rainbow-m-left_medium" />
      </Button>
      <br />
      <br />
      <Card style={containerStyles} isLoading={loading}>
        <div className='function-debug-response'>
          <Textarea
            id="function-debug-response"
            value={response}
            rows={10}
            style={containerStyles}
            className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
            readOnly
          />
        </div>
      </Card>
    </div>
    </>
  );
}


export default FunctionDebug;
