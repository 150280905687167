import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./containers/Home";
import About from "./containers/About";

export default () => {
    return (
        <Routes>
            <Route
                path="/"
                element={
                        <Home />
                }
            />
            <Route
                path="/about"
                element={
                        <About />
                }
            />
            <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
    )
}