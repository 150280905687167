import React from 'react';
import './About.css'
import { Card } from 'react-rainbow-components';


function About() {
  return (
    <div className='about'>
    <h1>About</h1>
    <Card>
      <p className='about-text'>
        This app is built using OpenAI API's with various different ML models. Before we welcome our new robot overlords, we might as well use them to make our lives easier.
        <br />
        <br />
        <a href='https://openai.com/'>OpenAI</a>
      </p>
    </Card>
    </div>
  )
}
export default About