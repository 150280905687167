import './Home.css';
import React, { useState, useCallback } from "react";
import { Modal, RadioButtonGroup, VerticalNavigation, VerticalSection, VerticalItem, Picklist, Option } from 'react-rainbow-components';

import ErrorAssist from '../components/ErrorAssist';
import FunctionDebug from '../components/FunctionDebug';
import CodeCreate from '../components/CodeCreate';


function Home() {

  const [error, setError] = useState(null);
  const [toggleValue, setToggleValue] = useState('debug');
  const [debugToggleValue, setDebugToggleValue] = useState('item-1');
  const [dropdown, setDropdown] = useState({ name: 'nodejs', label: 'NodeJS' });

  const setErrorState = useCallback(val => {
    setError(val);
  }, [setError]);

  const toggle = [
    { value: 'create', label: 'Create' },
    { value: 'debug', label: 'Debug' }
  ];

  const errorOption = (
    <div className='error-content'>
      <div className='error-nav-column'>
        <VerticalNavigation
          selectedItem={debugToggleValue}
          onSelect={(e, name)=>setDebugToggleValue(name)}
        >
          <VerticalSection>
            <VerticalItem name="item-1" label="Error Assist" />
            <VerticalItem name="item-2" label="Function Debug" />
          </VerticalSection>
        </VerticalNavigation>
      </div>
      <div className='error-box-column'>
        {debugToggleValue === 'item-1'
          ? <ErrorAssist setErrorState={setErrorState} />
          : <FunctionDebug setErrorState={setErrorState} />
        }
      </div>
    </div>
  );

  const createOption = (
    <div className='create-content'>
      <div className='create-select-column'>
        <Picklist
          id="language-select"
          value={dropdown}
          label="Select Language"
          onChange={(e)=>setDropdown(e)}
        >
          <Option name="header" label="Languages" variant="header" />
          <Option name="nodejs" label="NodeJS" />
          <Option name="python" label="Python" />
          <Option name="typescript" label="Typescript" />
          <Option name="go" label="Go" />
          <Option name="rust" label="Rust" />
        </Picklist>
      </div>
      <div className='error-box-column'>
        <CodeCreate setErrorState={setErrorState} language={dropdown.name}/>
      </div>
    </div>
  )

  return (
    <>
      <div className='toggle'>
        <RadioButtonGroup
          id="radio-button-group-component-1"
          options={toggle}
          value={toggleValue}
          onChange={(e) => setToggleValue(e.target.value)}
        />
      </div>
      <div style={{width: "100%", display: "flex"}}>
        { toggleValue === 'debug' ? errorOption : createOption }
      </div>
      <Modal id="modal-1" isOpen={error} onRequestClose={() => setError(null)}>
        <p>
          ERROR: {error}
        </p>
      </Modal>
    </>
  );
}


export default Home;
