import './App.css';
import React from "react";
import Routes from "./Routes";
import NavBar from "./components/Navbar"


function App() {

  return (
    <>
      <NavBar />
      <Routes />
    </>
  );
}


export default App;
