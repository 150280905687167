import './ErrorAssist.css';
import React, { useState, useEffect } from "react";
import { Textarea, Button, Card, HelpText } from 'react-rainbow-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';


const containerStyles = {
  maxWidth: 700,
};

function ErrorAssist({setErrorState}) {

  const [query, setQuery] = useState('');
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [buttonClick, setButtonClick] = useState(null);
  const [count, setCount] = useState(0);

  const maxLength = 500
  const placeholder = `Enter error.

  ex. No ‘Access-Control-Allow-Origin’ header present`

  useEffect(() => {

    async function getResponse() {
      try {
        setLoading(true);
        var response = await fetch(`https://eqesbupceg.execute-api.us-east-1.amazonaws.com/explain?query=${query}`);
        if (!response.ok) {
          throw new Error(`HTTP Error: ${response.status}`);
        }
        response = await response.json();
        console.log(response.response);
        setResponse(response.response.trim());
      } catch (e) {
        console.log(e.message);
        setErrorState(e.message);
      } finally {
        setLoading(false);
      }
    }
    if (query) {
      getResponse();
    }
  }, [buttonClick]);

  async function handleClick() {
    const date = new Date();
    setButtonClick(date.toISOString());
  }


  return (
    <>
    <div>
      <h1>Error Assist</h1>
      <Textarea
        header={<HelpText style={{margin: "5px"}} text="Feel free to include context, such as what software is causing the problem. I.e. Prefix 'NodeJS' to the error to help isolate the cause."/>}
        id="query"
        maxLength={maxLength}
        value={query}
        rows={6}
        placeholder={placeholder}
        footer ={<div className='error-assist-footer'>{count}/{maxLength}</div>}
        style={containerStyles}
        className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
        onChange={e => {setQuery(e.target.value); setCount(e.target.value.length)}}
      />
      <br />
      <Button variant="brand" className="rainbow-m-around_medium" onClick={handleClick} isLoading={loading}>
        Let's Fix It...
        <FontAwesomeIcon icon={faArrowRight} className="rainbow-m-left_medium" />
      </Button>
      <br />
      <br />
      <Card style={containerStyles} isLoading={loading}>
        <div className='error-assist-response'>
          <Textarea
            id="response"
            value={response}
            rows={10}
            style={containerStyles}
            className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
            readOnly
          />
        </div>
      </Card>
    </div>
    </>
  );
}


export default ErrorAssist;
